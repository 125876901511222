<template>
	<div class="container" v-title :data-title="expertDetails.title">
    <div class="breadcrumbs-box">
      <div class="breadcrumbs">
        <i class="el-icon-s-home home-icon"></i>
        <div class="item">
          <router-link tag="div" to="/">首页</router-link>
        </div>
        <i class="el-icon-arrow-right right-icon"></i>
        <div class="item">
          <router-link tag="div" to="/expertList">专家人才</router-link>
        </div>
        <i class="el-icon-arrow-right right-icon"></i>
        <div class="item">
          <div>{{ expertDetails.title }}</div>
        </div>
      </div>
    </div>
    <div class="container-box">
      <div class="details-box">
        <div class="title">{{ expertDetails.expertName }}</div>
        <div class="sub-title">
          <div>类型：{{ expertDetails.typeName }}</div>
          <div>职务：{{ expertDetails.jobTitle }}</div>
        </div>
        <div class="details">
          <div v-html="expertDetails.summary"></div>
        </div>
      </div>
      <div class="other-news-list">
        <div class="title">
          推荐新闻
          <div>
            <i class="el-icon-arrow-right right-icon"></i>
            <i class="el-icon-arrow-right right-icon"></i>
          </div>
        </div>
        <ul class="news-box">
          <li class="news-item" v-for="item in 10" :key="item">
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getExpertInfo } from '../../network/expert'

export default {
  name: "ExpertDetails",
  data () {
    return {
      expertNumber: '',
      expertDetails: {},
    };
  },
  props: {
    
  },
  components: {
    
  },
  mounted() {
    const params = this.$route.query;
    if(params && params.expertNumber) {
      this.expertNumber = params.expertNumber
      this.getExpertInfo(this.expertNumber)
    }
  },
  methods: {
    
    // 网络请求
    getExpertInfo(id) {
      getExpertInfo(id).then(res=>{
        if(res.status===200&&res.data) {
          this.expertDetails = res.data
          document.title = this.expertDetails.expertName
          console.log('成果详情',this.expertDetails)
        }
      })
    },
  }
}
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  .breadcrumbs-box {
    width: 100%;
    height: 40px;
    background-color: #eee;
    .breadcrumbs {
      width: 1200px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .home-icon {
        color: var(--theme-text-color);
        font-size: 14px;
      }
      .right {
        color: #535353;
        font-size: 14px;
      }
      .item {
        margin-left: 10px;
        font-size: 14px;
        color: #535353;
        cursor: pointer;
      }
    }
  }
  .container-box {
    width: 1200px;
    margin: 20px auto 35px;
    display: flex;
    justify-content: space-between;
    .details-box {
      width: 870px;
      border: 1px solid #e5e5e5;
      padding: 35px;
      .title {
        font-size: 28px;
        color: #313131;
        font-weight: bold;
        text-align: center;
        line-height: 46px;
        margin-bottom: 10px;
      }
      .sub-title {
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid #dcdcdc;
        font-size: 14px;
        color: #666;
        line-height: 32px;
      }
      .details {
        padding-top: 25px;
      }
    }
    .other-news-list {
      width: 310px;
      .title {
        border-top: 10px solid var(--theme-color);
        background-color: #eee;
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 34px;
        font-size: 20px;
        font-weight: bold;
        color: var(--theme-text-color);
        .right-icon {
          color: var(--theme-text-color);
        }
      }
      .news-box {
        border: 1px solid #e5e5e5;
        padding: 10px 20px;
        .news-item {
          padding: 0 20px;
          height: 35px;
          line-height: 35px;
          list-style-type: none;
          padding-left: 20px;
          color: #1b1b1b;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: var(--theme-color);
            rotate: 45deg;
            margin-right: 10px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
</style>
